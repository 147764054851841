import React from "react";
import SwrProvider from "./helper/swr";
import useChrome from "./hooks/useChrome";
import { Toaster } from "react-hot-toast";
import useUser from "./hooks/useUser";
import { Auth, Dash, Extension } from "./components";

function App() {
  const { isChrome } = useChrome();
  const { isAuth, loading } = useUser();
  const [appLoading, setAppLoading] = React.useState(true);

  React.useEffect(() => {
    if (appLoading && !loading) setAppLoading(false);
  }, [loading]);

  if (appLoading)
    return (
      <div className="h-screen w-screen flex items-center justify-center bg-gradient-to-tr bg-ebony  text-white">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          fill="currentColor"
          className="scale-150"
        >
          <circle cx="4" cy="12" r="3">
            <animate
              id="spinner_qFRN"
              begin="0;spinner_OcgL.end+0.25s"
              attributeName="cy"
              calcMode="spline"
              dur="0.6s"
              values="12;6;12"
              keySplines=".33,.66,.66,1;.33,0,.66,.33"
            />
          </circle>
          <circle cx="12" cy="12" r="3">
            <animate
              begin="spinner_qFRN.begin+0.1s"
              attributeName="cy"
              calcMode="spline"
              dur="0.6s"
              values="12;6;12"
              keySplines=".33,.66,.66,1;.33,0,.66,.33"
            />
          </circle>
          <circle cx="20" cy="12" r="3">
            <animate
              id="spinner_OcgL"
              begin="spinner_qFRN.begin+0.2s"
              attributeName="cy"
              calcMode="spline"
              dur="0.6s"
              values="12;6;12"
              keySplines=".33,.66,.66,1;.33,0,.66,.33"
            />
          </circle>
        </svg>
      </div>
    );

  return (
    <SwrProvider>
      <Toaster />
      <div className="bg-ebony">{isChrome ? <Extension /> : <>{isAuth ? <Dash /> : <Auth />}</>}</div>
    </SwrProvider>
  );
}

export default App;
