import { useCallback } from "react";

const useCookie = () => {
  const getCookie = useCallback(async (url: string, name?: string) => {
    if (name) {
      const cookie = await chrome.cookies.get({ url, name });

      return cookie?.value;
    }
    return await chrome.cookies.getAll({ domain: url });
  }, []);

  return { getCookie };
};

export default useCookie;
