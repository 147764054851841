import toast from "react-hot-toast";
import { AppConfig, clientId } from "../config";
import useSocket from "../hooks/useSocket";
import useStorage from "../hooks/useStorage";
import { axios_server } from "../helper";
import Cookies from "js-cookie";

export const Auth = () => {
  const socket = useSocket();
  const { update: lessyinUpdate } = useStorage(AppConfig.store.LESSYIN_DATA);

  const handleOauth = (path: string) => {
    const url = `${AppConfig.API_URL}/login/${path}?device=${clientId}`;

    if (path) {
      window.open(`${url}`, "_blank", "width=600,height=600");
    }

    socket.on("auth", (data: any) => {
      socket.off("auth");
      if (!data) {
        toast("Something went wrong, please try again later");
        return;
      }

      lessyinUpdate(data);
      Cookies.set(AppConfig.store.LESSYIN_TOKEN, data.accessToken);

      axios_server.defaults.headers.common["Authorization"] = `Bearer ${data.accessToken}`;

      toast.success(`Glad to see you again, ${data.given_name}`, {
        duration: 5000,
        position: "bottom-center",
      });
    });
  };

  return (
    <main className="bg-ebony h-screen w-screen ">
      <div className="container w-full h-full flex flex-col items-center justify-center space-y-20">
        <div className="space-y-5">
          <h1 className="text-white text-3xl md:text-5xl font-bold text-center">Ready to get started with Lessyin?</h1>
          <p className="text-white text-center text-sm md:text-base">
            Lessyin is a chrome extension that helps you to manage your LinkedIn connections and messages.
          </p>
        </div>
        <div className="relative inline-flex w-fit h-fit group ">
          <button
            onClick={() => handleOauth("linkedin")}
            className="text-center cursor-pointer gap-x-2  z-[999999] bg-royal-blue rounded-full px-8 py-4 text-white font-semibold flex items-center"
          >
            Authenticate with LinkedIn
            <svg
              width="24"
              height="24"
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clip-rule="evenodd"
              color="currentColor"
              className="fill-current text-white"
            >
              <path d="M21.883 12l-7.527 6.235.644.765 9-7.521-9-7.479-.645.764 7.529 6.236h-21.884v1h21.883z" />
            </svg>
          </button>
          <div className="absolute z-10  duration-1000 opacity-100 -inset-px bg-royal-blue rounded-full blur-lg group-hover:opacity-50 group-hover:-inset-1 group-hover:duration-200 animate-tilt"></div>
        </div>
      </div>
    </main>
  );
};
