import { useEffect, useState } from "react";
import { AppConfig } from "../config";
import useCookie from "./useCookie";
import useStorage from "./useStorage";
import useUser from "./useUser";

const useChrome = () => {
  const { getCookie } = useCookie();
  const { isAuth } = useUser();
  const { update } = useStorage(AppConfig.store.LESSYIN_TOKEN);
  const [isChrome, setIsChrome] = useState<Boolean>(false);

  useEffect(() => {
    if (window.chrome && chrome.runtime && chrome.runtime.id) {
      setIsChrome(true);
      if (!isAuth) {
        getCookie("http://localhost", AppConfig.store.LESSYIN_TOKEN)
          .then((cookie) => {
            if (cookie && typeof cookie === "string") update(cookie);
          })
          .catch((error) => {
            console.error("Error in useEffect:", error);
          });
      }
    }
  }, [getCookie, isAuth, update]);
  return { isChrome, isAuth };
};

export default useChrome;
