import { useMemo, useRef } from "react";
import { Socket, io } from "socket.io-client";
import { AppConfig, clientId } from "../config";

const activeConnections = new Map();

const useSocket = () => {
  const prevClientIdRef: any = useRef();

  const socket = useMemo(() => {
    if (prevClientIdRef.current !== clientId) {
      const prevSocket = activeConnections.get(prevClientIdRef.current);
      if (prevSocket) {
        prevSocket.close();
        activeConnections.delete(prevClientIdRef.current);
      }
      prevClientIdRef.current = clientId;
    }

    const existingSocket: Socket = activeConnections.get(clientId);
    if (existingSocket) {
      return existingSocket;
    }

    const newSocket = io(AppConfig.API_URL, {
      transports: ["websocket"],
      timeout: 10000,
    });

    activeConnections.set(clientId, newSocket);

    newSocket.on("connect", () => {
      newSocket.emit("identify", clientId);
    });

    newSocket.on("disconnect", () => {
      newSocket.emit("destroy", clientId);

      const socketToClose = activeConnections.get(clientId);
      if (socketToClose) {
        socketToClose.close();
        activeConnections.delete(clientId);
      }
    });

    return newSocket;
  }, []);

  return socket;
};

export default useSocket;
