import React from "react";
import { SWRConfig } from "swr";

const SwrProvider = ({ children }: { children?: React.ReactNode }) => {
  return (
    <SWRConfig
      value={{
        provider: localStorageProvider,
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          // Never retry on 400 and above.
          if (error?.response?.status >= 400) return;

          // Only retry up to 3 times.
          if (retryCount >= 3) return;
          // Retry after 10 seconds.
          setTimeout(() => revalidate({ retryCount }), 10000);
        },
      }}
    >
      {children}
    </SWRConfig>
  );
};

export default SwrProvider;

function localStorageProvider(): any {
  const map = new Map(JSON.parse(localStorage.getItem("lessyin-app-cache") || "[]"));

  // Before unloading the app, we write back all the data into `localStorage`.
  window.addEventListener("beforeunload", () => {
    const appCache = JSON.stringify(Array.from(map.entries()));
    localStorage.setItem("lessyin-app-cache", appCache);
  });

  return map;
}
