import React, { useCallback } from "react";

const useStorage = (name: string) => {
  const [state, setState] = React.useState<any>();
  const update = useCallback(
    (data: any) => {
      if (typeof window !== "undefined") {
        window.localStorage.setItem(name, typeof data === "string" ? data : JSON.stringify(data));
      }
    },
    [name],
  );

  const remove = useCallback(() => {
    if (typeof window !== "undefined") {
      window.localStorage.removeItem(name);
    }
  }, [name]);

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      setState(window.localStorage.getItem(name) || null);
    }
  }, [update, remove, name]);
  return { data: state, update, remove };
};

export default useStorage;
