import useSWR from "swr";
import { get } from "../requests";

const useSearch = (q: string) => {
  const { data, error, mutate, isLoading } = useSWR(q ? `/search?q=${q}` : null, get);

  return {
    results: data?.data,
    loading: isLoading,
    error: error,
    mutate,
  };
};
export default useSearch;
