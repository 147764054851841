import { useState } from "react";
import useUser from "../hooks/useUser";
import Cookies from "js-cookie";
import { AppConfig } from "../config";
import useStorage from "../hooks/useStorage";
import useSearch from "../hooks/useSearch";
import { useDebounce } from "usehooks-ts";

export const Dash = () => {
  const { user } = useUser({ useStore: true });
  const { remove } = useStorage(AppConfig.store.LESSYIN_DATA);
  const [q, setQuery] = useState("");
  const [filter, setFilter] = useState("");
  const value = useDebounce(q, 500);
  const { results, loading, mutate } = useSearch(value);

  const handleLogout = () => {
    Cookies.remove(AppConfig.store.LESSYIN_TOKEN);
    remove();
    window.location.reload();
  };
  return (
    <main className="h-screen w-screen text-white container flex flex-col">
      <nav className="py-10">
        <ul className="flex items-center justify-between">
          <li className="flex items-center gap-x-5">
            <img src={user?.picture} className="w-14 h-14 rounded-full" alt="User Image" />
            <h1 className="text-2xl">
              Welcome back <b>{user?.given_name}</b> 👋🏼{" "}
            </h1>
          </li>
          <li>
            <button onClick={handleLogout}>Logout</button>
          </li>
        </ul>
      </nav>
      <div className="flex-grow w-full flex flex-col items-center space-y-5 overflow-y-auto hide_scrollbar">
        <span className="text-center text-lg">Who would you like to search for today?</span>
        <div className="flex flex-col items-center space-y-2">
          <input
            type="search"
            value={q}
            onChange={(e) => setQuery(e.target.value)}
            placeholder="Search..."
            className="px-5 py-4 rounded-full bg-white/10 w-96 text-white text-lg font-semibold focus:outline-none"
          />

          {/* <label
                        htmlFor=""
                        className="space-x-2 flex items-center text-center"
                    >
                        <input type="checkbox" id="filter" />
                        <span>Filter By Connection</span>
                    </label> */}
        </div>
        <div className="w-full flex flex-col items-center divide-y divide-gray-900/20">
          {loading ? (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              className="scale-150"
            >
              <circle cx="4" cy="12" r="3">
                <animate
                  id="spinner_qFRN"
                  begin="0;spinner_OcgL.end+0.25s"
                  attributeName="cy"
                  calcMode="spline"
                  dur="0.6s"
                  values="12;6;12"
                  keySplines=".33,.66,.66,1;.33,0,.66,.33"
                />
              </circle>
              <circle cx="12" cy="12" r="3">
                <animate
                  begin="spinner_qFRN.begin+0.1s"
                  attributeName="cy"
                  calcMode="spline"
                  dur="0.6s"
                  values="12;6;12"
                  keySplines=".33,.66,.66,1;.33,0,.66,.33"
                />
              </circle>
              <circle cx="20" cy="12" r="3">
                <animate
                  id="spinner_OcgL"
                  begin="spinner_qFRN.begin+0.2s"
                  attributeName="cy"
                  calcMode="spline"
                  dur="0.6s"
                  values="12;6;12"
                  keySplines=".33,.66,.66,1;.33,0,.66,.33"
                />
              </circle>
            </svg>
          ) : null}
          {results?.map((item: TItem) => (
            <Item {...item} key={item.url} />
          ))}
        </div>
      </div>
      <div className="my-10 text-center">
        <p>
          Make sure you have LessyIn chrome extension installed and logged in to your account to use it properly. If you
          don't have it, please install it from here:
        </p>
        <a
          href="https://lessyin.brimble.app/build.zip"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-blue-500"
          download
        >
          Download
        </a>{" "}
        and see{" "}
        <a
          href="https://developer.chrome.com/docs/extensions/mv3/getstarted/development-basics/#load-unpacked"
          target="_blank"
          rel="noreferrer"
          className="font-bold text-gray-500"
          download
        >
          how to setup
        </a>
      </div>
    </main>
  );
};

type TItem = {
  name: string;
  bio: string;
  badge: string;
  location: string;
  url: string;
};

const Item = ({ badge, bio, location, name, url }: TItem) => (
  <div className="w-full p-5 flex justify-between hover:bg-gray-900/10 items-center">
    <div className="flex-grow flex flex-col space-y-2">
      <span className="text-lg">{name}</span>
      <span className="text-sm line-clamp-2 text-ellipsis">
        {bio} - {badge}
      </span>
    </div>
    <div className="">
      <a href={url} target="_blank" className="text-royal-blue whitespace-nowrap">
        View profile
      </a>
    </div>
  </div>
);
