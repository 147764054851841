import useSWR, { SWRConfiguration } from "swr";
import useStorage from "./useStorage";
import { AppConfig } from "../config";
import { get } from "../requests";

type User = {
  email: string;
  email_verified: boolean;
  family_name: string;
  given_name: string;
  locale: { country: string; language: string };
  name: string;
  picture: string;
  sub: string;
  token: string;
};

const useUser = (payload?: { useStore?: boolean }, options?: SWRConfiguration) => {
  const { update, data: userData } = useStorage(AppConfig.store.LESSYIN_DATA);

  const { data, error, mutate, isLoading } = useSWR("/me", get, {
    ...options,
    onSuccess: (data: { data: User }) => update(data?.data),
    fallbackData: payload?.useStore ? { data: userData } : null,
  });

  return {
    user: data?.data as User,
    loading: isLoading,
    error: error,
    isAuth: Boolean(Object.keys(data?.data || {}).length),
    mutate,
  };
};
export default useUser;
