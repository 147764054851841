import { useEffect, useState } from "react";
import useCookie from "../hooks/useCookie";
import { post } from "../requests";
import useUser from "../hooks/useUser";

export const Extension = () => {
  const { isAuth } = useUser();
  const { getCookie } = useCookie();
  const [cookies, setCookies] = useState<string>("");
  const [csrf, setCsrf] = useState<string | null>();

  useEffect(() => {
    getCookie("linkedin.com")
      .then((cookies) => {
        if (Array.isArray(cookies)) {
          setCookies(cookies.map((cookie) => `${cookie.name}=${cookie.value}`).join("; "));
          const csrf = cookies.find((cookie) => cookie.name === "JSESSIONID");
          if (csrf) setCsrf(csrf.value.split('"').join(""));
        }
      })
      .catch(console.log);
  }, [getCookie]);

  useEffect(() => {
    if (cookies && csrf) post("/save", { cookies, csrf }).catch(console.log);
  }, [cookies, csrf]);

  return (
    <>
      <h1>LessyIn 🪐</h1>
      {isAuth ? <h2>Logged in</h2> : <button onClick={() => window.location.reload()}>Sync</button>}
    </>
  );
};
