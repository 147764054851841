import axios, { AxiosError } from "axios";
import { AppConfig } from "../config";
import toast from "react-hot-toast";
import Cookies from "js-cookie";

const token = Cookies.get(AppConfig.store.LESSYIN_TOKEN) || localStorage.getItem(AppConfig.store.LESSYIN_TOKEN);
const headers: { [key: string]: string } = {
  "Access-Control-Allow-Origin": "*",
  "content-type": "application/json",
};

if (token) headers.Authorization = `Bearer ${token}`;

const axios_server = axios.create({
  baseURL: AppConfig.API_URL,
  headers,
});

const handleRequestError = (error: AxiosError) => {
  const { response, request, message } = error;

  if (response?.data) {
    const { data } = response as { data: any };
    if (data?.errors?.length) {
      data?.errors.forEach((error: any) => {
        toast.error(error.msg);
      });
    } else if (data?.message) {
      toast.error(data.message);
    } else {
      toast.error("Something went wrong: Server couldn't fullfil request");
    }
  } else if (request && message === "Network Error") {
    toast.error("Network error; Please check your internet connection.");
  } else {
    toast.error("Something went wrong: " + message);
  }
};

export { axios_server, handleRequestError };
